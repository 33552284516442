import React, { useRef, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Timeline from './components/Timeline';
import Home from './pages/Home';
import ExperiencePage from './pages/ExperiencePage';
import HomeIcon from '@mui/icons-material/Home';


function ScrollToTop({ containerRef }) {
  const location = useLocation();

  const getLeftScrollPosition = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth < 640) return 430; // Mobile (sm)
    if (screenWidth < 768) return 410; // Tablet (md)
    if (screenWidth < 1024) return 410; // Desktop (lg)
    return 390; // Large Desktop (xl and above)
  };

  useEffect(() => {
    if (location.pathname === '/') {
      if (containerRef.current) {
        containerRef.current.scrollTo({ left: getLeftScrollPosition(), behavior: 'smooth' });
      }
    }
  }, [location, containerRef]);

  return null;
}


function HomeButton() {
  const navigate = useNavigate();

  return (
    <HomeIcon
      className="fixed top-4 ml-4 cursor-pointer"
      onClick={() => navigate('/')}
    />
  );
}

function App() {
  const containerRef = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0);

  let baseLeft = 30;

  return (
    <Router>
      <ScrollToTop containerRef={containerRef} />
      <div
        className=" h-screen w-screen overflow-y-hidden flex bg-darkBackground text-dirtyWhite touch-pan-x scroll-auto font-customFont"
        ref={containerRef}
      // onScroll={location.pathname.startsWith('/experience') ? handleScroll : null}
      >
        <div className="flex flex-col justify-between min-w-full  pt-10">
          <HomeButton />

          <div className="flex-1 min-w-full max-h-[75%]  overflow-y-clip ">
            <Routes>
              <Route path="/" element={<Home baseLeft={baseLeft} />} />
              <Route path="/experience/:id" element={<ExperiencePage scrollPosition={scrollPosition} />} />
            </Routes>
          </div>

          <div className='w-full  flex-1 flex flex-nowrap min-w-[165rem] max-h-[25%]'>
            <Timeline containerRef={containerRef} baseLeft={baseLeft} setScrollPosition={setScrollPosition} />
          </div>
        </div>
      </div>
    </Router>
  );
}

export default App;
