import React from 'react';
import animeLogo from '../assets/images/anime_logo.jpg'
import StackToolsSection from '../components/StackToolsSection';
import LeftSide from '../components/LeftSide';

export default function Home({ baseLeft }) {

    return (
        <div className="flex justify-between flex-nowrap h-full min-w-max">

            <LeftSide />

            <div className="flex h-full min-w-max relative space-x-12 lg:space-x-28 overflow-hidden top-[3rem]" style={{ left: `${4}rem` }}>

                <div className="flex flex-col relative top-[6.5rem] min-w-[23rem]">

                    <div className="flex justify-between  space-x-4 px-4 ">

                        <div className=" h-24 flex flex-col justify-center ">
                            {/* Name */}
                            <h1 className="text-xl ">Mohammed Allam.</h1>

                            {/* Role */}
                            <h2 className="text-sm">Software Developer</h2>
                        </div>

                        {/* Image */}
                        <div className="w-24 h-24 rounded-md bg-gray-300">
                            {/* Placeholder for image */}
                            <img
                                src={animeLogo}
                                alt="Profile"
                                className=" object-cover w-full h-full"
                            />
                        </div>
                    </div>

                </div>

                <div className="relative top-[6rem] text-left max-w-[23rem] lg:max-w-[30rem] h-full">
                    <p className='text-lg font-semibold mb-2'>Hello</p>
                    <p className='text-sm leading-8 lg:leading-9'>
                        I created this website to showcase my experiences and skill set with a unique, personal design touch. Click on each experience below to learn more, and feel free to reach out and say hello!
                    </p>
                </div>


                <div className="flex flex-col justify-start align-top items-start h-1/2">
                    <StackToolsSection />
                </div>
            </div>
        </div >
    )
}


