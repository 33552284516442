import React, { useEffect } from 'react';
import Experience from './Experience';
import { useLocation } from 'react-router-dom';
import experienceData from '../data/ExperienceData';

export default function Timeline({ containerRef, baseLeft, setScrollPosition }) {
    const location = useLocation();
    const currentId = location.pathname.split('/')[2];

    const circleCount1 = 44; // Number of circles
    const circles1 = Array(circleCount1).fill(0);

    const circleCount2 = 20; // Number of circles
    const circles2 = Array(circleCount2).fill(0);

    useEffect(() => {
        const TimelineContainerRef = containerRef.current;

        if (currentId && TimelineContainerRef) {
            const experienceIndex = experienceData.findIndex(exp => String(exp.id) === currentId);

            if (experienceIndex !== -1) {
                const get_baseLeft = experienceData[experienceIndex].baseLeft;
                const calculatedScrollPosition = (get_baseLeft * 16) - (window.innerWidth < 640 ? 20 : 40);

                setScrollPosition(calculatedScrollPosition); // Set initial scroll position on load

                TimelineContainerRef.scrollTo({
                    left: calculatedScrollPosition,
                    behavior: 'smooth' // Enables smooth scrolling
                });
            }
        }
    }, [currentId, containerRef, setScrollPosition]);

    const handleExperienceClick = (experience) => {
        const newScrollPosition = (experience.baseLeft * 16) - (window.innerWidth < 640 ? 20 : 40);
        setScrollPosition(newScrollPosition); // Update scroll position on click
        // Scroll to the new position smoothly
        containerRef.current.scrollTo({
            left: newScrollPosition,
            behavior: 'smooth'
        });
    };

    return (
        <div className="relative z-10 w-full">
            <div className="absolute bottom-28 lg:bottom-24 left-0 right-0 min-w-max">
                <p className=' ml-[12.12rem]'>
                    ?
                </p>

                <div className="flex items-end gap-[0.5rem]">
                    {circles1.map((_, index) => (
                        <div
                            key={index}
                            className="w-[0.15rem] h-[0.15rem] rounded-full bg-dirtyWhite"
                        />
                    ))}

                    {/* Right Horizontal Line */}
                    <div className='h-[0.1rem] bg-white'
                        style={{ width: `${baseLeft + 101.5}rem` }}>
                    </div>

                    {circles2.map((_, index) => (
                        <div
                            key={index}
                            className="w-[0.15rem] h-[0.15rem] rounded-full bg-dirtyWhite"
                        />
                    ))}
                </div>

                {experienceData.map((experience) => (
                    <div key={experience.id} onClick={() => handleExperienceClick(experience)}>
                        <Experience
                            id={experience.id}
                            experience_name={experience.experience_name}
                            title={experience.title}
                            company={experience.company}
                            logo={experience.logo}
                            address={experience.location}
                            startDate={experience.startDate}
                            endDate={experience.endDate}
                            baseLeft={experience.baseLeft}
                            horizontalWidth={experience.horizontalWidth}
                            verticalHeight={experience.verticalHeight}
                            isActive={currentId === String(experience.id)}
                        />
                    </div>
                ))}

                {/* DoB */}
                <div className='relative'>
                    <div className=' absolute bg-white'
                        style={{ bottom: '0', width: '0.1rem', height: '1rem', left: `${baseLeft + 100 + 30}rem` }}>
                    </div>
                    <div className=' absolute bottom-[1.1rem]  '
                        style={{ left: `${baseLeft + 100 + 29.2}rem` }}>
                        <p className=' text-lineBlue text-center text-[0.6rem] lg:text-[0.7rem]'>1999</p>
                    </div>
                </div>

                <div className='absolute'
                    style={{ bottom: '0', left: `${baseLeft + 100 + 36.87}rem` }}>
                    <p className='   '>?</p>
                </div>
            </div>
        </div>
    );
}






