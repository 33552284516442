import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import experienceData from '../data/ExperienceData';
import courseData from '../data/CoursesData';
import SchoolIcon from '@mui/icons-material/School';

export default function ExperiencePage({ scrollPosition }) {
    const { id } = useParams(); // Get the ID from the URL
    const experienceIndex = parseInt(id, 10) - 1;
    const experiencePageRef = useRef(null);
    const [expandedIndex, setExpandedIndex] = useState(null);

    const experience = experienceData[experienceIndex];

    useEffect(() => {
        if (experience && experiencePageRef.current) {
            const experiencePageContainerRef = experiencePageRef.current;
            experiencePageContainerRef.style.transition = "transform 0.3s ease-out";

            if (window.innerWidth < 640) { // small screen
                experiencePageContainerRef.style.transform = `translateX(${scrollPosition + 20}px)`;

            } else { // large screen
                experiencePageContainerRef.style.transform = `translateX(${scrollPosition + 40}px)`;
            }
        }
    }, [experience, scrollPosition]);

    if (!experience) {
        return <div>Experience not found.</div>; // Handle invalid IDs
    }

    return (
        <div className="flex flex-col flex-nowrap min-w-max h-full relative top-[1.5rem] space-y-4 lg:space-y-4 " ref={experiencePageRef}>

            <div className="flex flex-col">
                {/* main header section */}
                <div className=" flex pb-4 pr-4 space-x-4 max-w-full">
                    {/* Image container with fixed width */}
                    <div className=" h-12 w-12 mt-2">
                        {experience.logo ? (
                            <img
                                src={experience.logo}
                                alt="Logo"
                                className="object-cover w-full h-full rounded-xl"
                            />
                        ) : (
                            <SchoolIcon style={{ fontSize: '3rem' }} />
                        )}
                    </div>
                    <div className="space-y-1 h-auto w-[70%] flex flex-col justify-start align-top">
                        <h1 className="text-[1rem] whitespace-nowrap ">{experience.title}</h1>
                        <h2 className="text-[0.8rem]">{experience.company}</h2>
                        <p className="text-[0.6rem] text-lineBlue">
                            {experience.startDate} {experience.startDate && ' - '} {experience.endDate}
                        </p>
                    </div>
                </div>

            </div>
            <div className="flex space-x-12 lg:space-x-16 whitespace-break-spaces">
                <div className="h-full max-w-[23rem] lg:max-w-[35rem]  leading-6 lg:leading-9">
                    <p className="text-[0.8rem] ">{experience.shortDescription}</p>
                </div>

                <div className="h-full pb-4 max-w-[23rem] lg:max-w-[35rem]  leading-6 lg:leading-9">
                    <p className="text-[0.8rem]">{experience.fullDescription1}</p>
                </div>
                <div className="h-full pb-4 max-w-[23rem] lg:max-w-[35rem]  leading-6 lg:leading-9">
                    <p className="text-[0.8rem]">{experience.fullDescription2}</p>
                </div>


                {experience.experience_name === "uofc-computer-science" && (
                    <>
                        <div className="h-full pb-4 max-w-[23rem] lg:max-w-[35rem] leading-6 lg:leading-9">
                            <p className="text-[0.8rem]">{experience.fullDescription3}</p>
                        </div>

                        <div className="h-full pb-4 max-w-[23rem] lg:max-w-[35rem] leading-6 lg:leading-9">
                            <p className="text-[0.8rem]">{experience.fullDescription4}</p>
                        </div>

                        <div className="w-[210rem] flex flex-wrap gap-5 leading-none">
                            {courseData.map((course, index) => (
                                <div
                                    key={index}
                                    onClick={() => setExpandedIndex(expandedIndex === index ? null : index)} // Toggle expand
                                    className="rounded-xl shadow-2xl drop-shadow-md bg-darkBackground px-4 pb-4 space-y-2 overflow-y-hidden text-dirtyWhite hover:shadow-2xl transition-shadow cursor-pointer"
                                    style={{ width: "250px", height: expandedIndex === index ? "auto" : "90px" }}
                                >

                                    <h2 className="font-semibold text-sm">{course.courseName}</h2>
                                    <p className="whitespace-nowrap text-[#6C8FAF] text-[0.6rem]">{course.courseCode}</p>

                                    <p className={`text-[0.65rem] text-dirtyWhite leading-3 ${expandedIndex === index ? '' : 'line-clamp-2'}`}>
                                        {course.description}
                                    </p>
                                </div>
                            ))}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}
