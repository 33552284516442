import React from 'react';
import stackToolsData from '../data/StackToolsData';
import Dot from './Dot';

const StackToolsSection = () => {
    return (
        <>
            {stackToolsData.map((section, sectionIndex) => (
                <div key={sectionIndex} className="flex flex-col">
                    {/* Section Title */}
                    <p className=" text-[0.7rem] lg:text-[1rem] font-semibold">{section.title}</p>

                    {/* Technologies List */}
                    <div className=" text-xs flex py-3 lg:py-3">
                        {section.technologies.map((tech, techIndex) => (
                            <div key={techIndex} className="flex items-center">
                                {/* Technology Box */}
                                <div className="flex items-center justify-around h-[1.5rem] px-2">
                                    {tech.icon && <span>{tech.icon}</span>}
                                    <p>{tech.name}</p>
                                </div>

                                {/* Dot Separator */}
                                {techIndex < section.technologies.length - 1 && (
                                    <Dot className size="0.10rem" />
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </>
    );
};

export default StackToolsSection;
